import React from "react";
import SEO from "../components/Seo";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="prose">
      <h1>NOT FOUND</h1>
      <p>Sorry, you just hit a route that doesn&#39;t exist.</p>
    </div>
  </>
);

export default NotFoundPage;
